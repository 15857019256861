<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <a
          :href="`${apiUrl}/product/${item.slug}`"
          target="_blank"
          class="btn back-to-list btn-outline-info view-on-front"
        >
          <feather-icon
            icon="EyeIcon"
            size="16"
            class="align-middle"
          />
        </a>
        <b-button
          type="button"
          variant="outline-secondary"
          class="back-to-list"
          @click="toList"
        >
          <feather-icon
            icon="CornerUpLeftIcon"
            size="16"
            class="align-middle"
          />
        </b-button>
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('administration.products.tabs.general.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_h1.label')"
                        :label-for="'meta_h1.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_h1.' + language.code"
                          v-model="item.meta_h1[language.code]"
                          :state="errors && errors['meta_h1.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_h1.' + language.code]">
                          {{ errors['meta_h1.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_title.label')"
                        :label-for="'meta_title.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_title.' + language.code"
                          v-model="item.meta_title[language.code]"
                          :state="errors && errors['meta_title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_title.' + language.code]">
                          {{ errors['meta_title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_description.label')"
                        :label-for="'meta_description.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_description.' + language.code"
                          v-model="item.meta_description[language.code]"
                          :state="errors && errors['meta_description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_description.' + language.code]">
                          {{ errors['meta_description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_keywords.label')"
                        :label-for="'meta_keywords.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_keywords.' + language.code"
                          v-model="item.meta_keywords[language.code]"
                          :state="errors && errors['meta_keywords.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_keywords.' + language.code]">
                          {{ errors['meta_keywords.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.canonical.label')"
                        :label-for="'canonical.' + language.code"
                      >
                        <b-form-input
                          :id="'canonical.' + language.code"
                          v-model="item.canonical[language.code]"
                          :state="errors && errors['canonical.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['canonical.' + language.code]">
                          {{ errors['canonical.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.description.label')"
                        :label-for="'description.' + language.code"
                        :state="errors && errors['description.' + language.code] ? false : null"
                      >
                        <quill-editor
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :options="editorOptions"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                          {{ errors['description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab
              :title="$t('administration.products.tabs.data.label')"
              @click="loadDataTab"
            >
              <b-overlay
                :show="!loading.categories"
                rounded="sm"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-form-group
                      :label="$t('form.slug.label')"
                      label-for="slug"
                    >
                      <b-form-input
                        id="slug"
                        v-model="item.slug"
                        :state="errors && errors.slug ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.slug">
                        {{ errors.slug[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('form.categories.label')"
                      label-for="categories"
                      :state="errors && errors.categories ? false : null"
                    >
                      <treeselect
                        id="categories"
                        v-model="item.categories"
                        :multiple="true"
                        :options="categoryOptions"
                        :normalizer="normalizer"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.categories">
                        {{ errors.categories[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('form.stickers.label')"
                      label-for="stickers"
                      :state="errors && errors.stickers ? false : null"
                    >
                      <v-select
                        id="stickers"
                        v-model="item.stickers"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="stickersOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :multiple="true"
                        input-id="stickers"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.stickers">
                        {{ errors.stickers[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.status.label')"
                      label-for="status"
                      :state="errors && errors.status ? false : null"
                    >
                      <v-select
                        id="status"
                        v-model="item.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="status"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.status">
                        {{ errors.status[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.indexing_status.label')"
                      label-for="indexing_status"
                      :state="errors && errors.indexing_status ? false : null"
                    >
                      <v-select
                        id="indexing_status"
                        v-model="item.indexing_status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="indexing_status"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.indexing_status">
                        {{ errors.indexing_status[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.upc.label')"
                      label-for="upc"
                    >
                      <v-select
                        id="upc"
                        v-model="item.upc"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="upc"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.upc">
                        {{ errors.upc[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="hidden"
                  >
                    <b-form-group
                      :label="$t('form.code.label')"
                      label-for="code"
                    >
                      <b-form-input
                        id="code"
                        v-model="item.code"
                        :state="errors && errors.code ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.code">
                        {{ errors.code[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.sku.label')"
                      label-for="sku"
                    >
                      <b-form-input
                        id="sku"
                        v-model="item.sku"
                        :state="errors && errors.sku ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.sku">
                        {{ errors.sku[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.ean.label')"
                      label-for="ean"
                    >
                      <b-form-input
                        id="ean"
                        v-model="item.ean"
                        :state="errors && errors.ean ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.ean">
                        {{ errors.ean[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="hidden"
                  >
                    <b-form-group
                      :label="$t('form.isbn.label')"
                      label-for="isbn"
                    >
                      <b-form-input
                        id="isbn"
                        v-model="item.isbn"
                        :state="errors && errors.isbn ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.isbn">
                        {{ errors.isbn[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="hidden"
                  >
                    <b-form-group
                      :label="$t('form.min_quantity.label')"
                      label-for="min_quantity"
                    >
                      <b-form-input
                        id="min_quantity"
                        v-model="item.min_quantity"
                        type="number"
                        :state="errors && errors.min_quantity ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.min_quantity">
                        {{ errors.min_quantity[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.available_at.label')"
                      label-for="available_at"
                      :state="errors && errors.available_at ? false : null"
                    >
                      <b-form-datepicker
                        id="available_at"
                        v-model="item.available_at"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.available_at">
                        {{ errors.available_at[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.novelty_until.label')"
                      label-for="novelty_until"
                      :state="errors && errors.novelty_until ? false : null"
                    >
                      <b-form-datepicker
                        id="novelty_until"
                        v-model="item.novelty_until"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      />

                      <b-form-invalid-feedback v-if="errors && errors.novelty_until">
                        {{ errors.novelty_until[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    class="hidden"
                  >
                    <b-form-group
                      :label="$t('form.stock_status.label')"
                      label-for="stock_status"
                      :state="errors && errors.stock_status ? false : null"
                    >
                      <v-select
                        id="stock_status"
                        v-model="item.stock_status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="stockStatusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="stock_status"
                      />
                      <b-form-invalid-feedback v-if="errors && errors.stock_status">
                        {{ errors.stock_status[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.1c_id.label')"
                      label-for="1c_id"
                    >
                      <b-form-input
                        id="1c_id"
                        v-model="item['1c_id']"
                        :state="errors && errors['1c_id'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['1c_id']">
                        {{ errors['1c_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab :title="$t('administration.products.tabs.video.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.video.label')"
                    label-for="video"
                  >
                    <b-form-input
                      id="video"
                      v-model="item.video"
                      :state="errors && errors.video ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.video">
                      {{ errors.video[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('administration.products.tabs.images.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group :label="$t('form.avatar.label')">
                    <div class="mb-75">
                      <InitFileManager
                        field="avatar"
                        type="product_avatar"
                        :multiple="false"
                        @fm-selected="selectedAvatar"
                      />
                    </div>
                    <!-- media -->
                    <div class="media">
                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="$options.filters.mediaUrl(item, 'avatar', '110x110')"
                            height="80"
                          />
                        </b-link>
                        <!--/ avatar -->

                        <!-- reset -->
                        <b-button
                          v-if="item.avatar"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.avatar = null"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                        <!--/ reset -->
                      </b-media-aside>
                    </div>
                    <!--/ media -->
                    <div class="mt-75">
                      <b-row
                        v-if="errors && errors.avatar"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors.avatar[0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="errors && errors['avatar.type']"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors['avatar.type'][0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="9"
                >
                  <b-form-group :label="$t('form.gallery.label')">
                    <div class="mb-75">
                      <InitFileManager
                        field="gallery"
                        type="product_gallery"
                        :multiple="true"
                        @fm-selected="selectedGallery"
                      />
                    </div>
                    <!-- media -->
                    <draggable
                      v-model="item.gallery"
                      class="media"
                    >
                      <b-media-aside
                        v-for="(file, index) in item.gallery"
                        :key="index"
                      >
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="file.url"
                            height="80"
                          />
                        </b-link>
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.gallery.splice(index, 1)"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                      </b-media-aside>
                    </draggable>
                    <!--/ media -->
                    <div class="mt-75">
                      <b-row
                        v-if="errors && errors.avatar"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors.avatar[0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="errors && errors['avatar.type']"
                      >
                        <b-col cols="12">
                          <b-form-invalid-feedback :state="false">
                            {{ errors['avatar.type'][0] }}
                          </b-form-invalid-feedback>
                        </b-col>
                      </b-row>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :title="$t('administration.products.tabs.stocks.label')"
              @click="loadStocks"
            >
              <b-overlay
                :show="!loading.stocks"
                rounded="sm"
              >
                <div class="repeater-form">
                  <b-row
                    v-for="(stock, index) in item.stocks"
                    :id="'stock' + index"
                    :key="index"
                    ref="row"
                  >
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('form.stocks.label')"
                        :label-for="'stocks.' + index + '.stock_id'"
                        :state="errors && errors['stocks.' + index + '.stock_id'] ? false : null"
                      >
                        <v-select
                          :id="'stocks.' + index + '.stock_id'"
                          v-model="stock.stock_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="stocksOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          :input-id="'stocks.' + index + '.stock_id'"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['stocks.' + index + '.stock_id']">
                          {{ errors['stocks.' + index + '.stock_id'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('form.quantity.label')"
                        :label-for="'stocks.' + index + '.quantity'"
                      >
                        <b-form-input
                          :id="'stocks.' + index + '.quantity'"
                          v-model="stock.quantity"
                          :state="errors && errors['stocks.' + index + '.quantity'] ? false : null"
                        />

                        <b-form-invalid-feedback v-if="errors && errors['stocks.' + index + '.quantity']">
                          {{ errors['stocks.' + index + '.quantity'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col
                      lg="2"
                      md="3"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeStock(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>{{ $t('general.delete') }}</span>
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                  </b-row>
                </div>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="addStock"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>{{ $t('general.add') }}</span>
                </b-button>

                <b-row
                  v-if="errors && errors.stocks"
                >
                  <b-col cols="12">
                    <b-form-invalid-feedback :state="false">
                      {{ errors.stocks[0] }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab
              :title="$t('administration.products.tabs.prices.label')"
              @click="loadRoles"
            >
              <b-overlay
                :show="!loading.roles"
                rounded="sm"
              >
                <div class="repeater-form">
                  <b-row
                    v-for="(price, index) in item.prices"
                    :id="'price' + index"
                    :key="index"
                    ref="row"
                  >
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('form.role.label')"
                        :label-for="'prices.' + index + '.role_id'"
                        :state="errors && errors['prices.' + index + '.role_id'] ? false : null"
                      >
                        <v-select
                          :id="'prices.' + index + '.role_id'"
                          v-model="price.role_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="rolesOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          :input-id="'prices.' + index + '.role_id'"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['prices.' + index + '.role_id']">
                          {{ errors['prices.' + index + '.role_id'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('form.price.label')"
                        :label-for="'prices.' + index + '.price'"
                      >
                        <b-form-input
                          :id="'prices.' + index + '.price'"
                          v-model="price.price"
                          :state="errors && errors['prices.' + index + '.price'] ? false : null"
                        />

                        <b-form-invalid-feedback v-if="errors && errors['prices.' + index + '.price']">
                          {{ errors['prices.' + index + '.price'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col
                      lg="2"
                      md="3"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removePrice(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>{{ $t('general.delete') }}</span>
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                  </b-row>
                </div>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="addPrice"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>{{ $t('general.add') }}</span>
                </b-button>

                <b-row
                  v-if="errors && errors.prices"
                >
                  <b-col cols="12">
                    <b-form-invalid-feedback :state="false">
                      {{ errors.prices[0] }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab :title="$t('administration.products.tabs.sets.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(set, index) in item.sets"
                  :id="'set' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.product.label')"
                      :label-for="'sets.' + index + '.entity_id'"
                      :state="errors && errors['sets.' + index + '.entity_id'] ? false : null"
                    >
                      <v-select
                        :id="'sets.' + index + '.entity_id'"
                        v-model="set.entity_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="productOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'sets.' + index + '.entity_id'"
                        :searchable="true"
                        @search="searchProduct"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['sets.' + index + '.entity_id']">
                        {{ errors['sets.' + index + '.entity_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.price.label')"
                      :label-for="'sets.' + index + '.price'"
                    >
                      <b-form-input
                        :id="'sets.' + index + '.price'"
                        v-model="set.price"
                        :state="errors && errors['sets.' + index + '.price'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['sets.' + index + '.price']">
                        {{ errors['sets.' + index + '.price'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeSet(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addSet"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.sets"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.sets[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :title="$t('administration.products.tabs.discounts.label')"
              @click="loadRoles"
            >
              <b-overlay
                :show="!loading.roles"
                rounded="sm"
              >
                <div class="repeater-form">
                  <b-row
                    v-for="(discount, index) in item.discounts"
                    :id="'discount' + index"
                    :key="index"
                    ref="row"
                  >
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <b-form-group
                        :label="$t('form.role.label')"
                        :label-for="'discounts.' + index + '.role_id'"
                        :state="errors && errors['discounts.' + index + '.role_id'] ? false : null"
                      >
                        <v-select
                          :id="'discounts.' + index + '.role_id'"
                          v-model="discount.role_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="rolesOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          :input-id="'discounts.' + index + '.role_id'"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.role_id']">
                          {{ errors['discounts.' + index + '.role_id'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-form-group
                        :label="$t('form.price.label')"
                        :label-for="'discounts.' + index + '.price'"
                      >
                        <b-form-input
                          :id="'discounts.' + index + '.price'"
                          v-model="discount.price"
                          :state="errors && errors['discounts.' + index + '.price'] ? false : null"
                        />

                        <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.price']">
                          {{ errors['discounts.' + index + '.price'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-form-group
                        :label="$t('form.started_at.label')"
                        :label-for="'discounts.' + index + '.started_at'"
                        :state="errors && errors['discounts.' + index + '.started_at'] ? false : null"
                      >
                        <b-form-datepicker
                          :id="'discounts.' + index + '.started_at'"
                          v-model="discount.started_at"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        />

                        <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.started_at']">
                          {{ errors['discounts.' + index + '.started_at'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-form-group
                        :label="$t('form.ended_at.label')"
                        :label-for="'discounts.' + index + '.ended_at'"
                        :state="errors && errors['discounts.' + index + '.ended_at'] ? false : null"
                      >
                        <b-form-datepicker
                          :id="'discounts.' + index + '.ended_at'"
                          v-model="discount.ended_at"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        />

                        <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.ended_at']">
                          {{ errors['discounts.' + index + '.ended_at'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col
                      lg="2"
                      md="3"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeDiscount(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>{{ $t('general.delete') }}</span>
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                  </b-row>
                </div>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="addDiscount"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>{{ $t('general.add') }}</span>
                </b-button>

                <b-row
                  v-if="errors && errors.discounts"
                >
                  <b-col cols="12">
                    <b-form-invalid-feedback :state="false">
                      {{ errors.discounts[0] }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab
              :title="$t('administration.products.tabs.attributes.label')"
              @click="loadAttributes"
            >
              <b-overlay
                :show="!loading.attributes"
                rounded="sm"
              >
                <div class="repeater-form">
                  <b-row
                    v-for="(attribute, index) in item.attributes"
                    :id="'attribute' + index"
                    :key="index"
                    ref="row"
                  >
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('form.attribute_id.label')"
                        :label-for="'attributes.' + index + '.attribute_id'"
                        :state="errors && errors['attributes.' + index + '.attribute_id'] ? false : null"
                      >
                        <v-select
                          :id="'attributes.' + index + '.attribute_id'"
                          v-model="attribute.attribute_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="attributesOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          :input-id="'attributes.' + index + '.attribute_id'"
                          @input="loadAttributeValue($event, index)"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.attribute_id']">
                          {{ errors['attributes.' + index + '.attribute_id'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('form.attribute_value_id.label')"
                        :label-for="'attributes.' + index + '.attribute_value_id'"
                        :state="errors && errors['attributes.' + index + '.attribute_value_id'] ? false : null"
                      >
                        <v-select
                          :id="'attributes.' + index + '.attribute_value_id'"
                          v-model="attribute.attribute_value_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="attributeValuesOptions[`attribute_${attribute.attribute_id}`]"
                          :disabled="!attributeValuesOptions[`attribute_${attribute.attribute_id}`]"
                          :reduce="val => val.value"
                          :clearable="false"
                          :input-id="'attributes.' + index + '.attribute_value_id'"
                          :taggable="true"
                          :create-option="val => ({ value: `new_${val}`, label: val })"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.attribute_value_id']">
                          {{ errors['attributes.' + index + '.attribute_value_id'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('form.is_link.label')"
                        :label-for="'attributes.' + index + '.is_link'"
                      >
                        <b-form-checkbox
                          :id="'attributes.' + index + '.is_link'"
                          v-model="attribute.is_link"
                          :state="errors && errors['attributes.' + index + '.is_link'] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.is_link']">
                          {{ errors['attributes.' + index + '.' + '.is_link'][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col
                      lg="2"
                      md="3"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeAttribute(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>{{ $t('general.delete') }}</span>
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                  </b-row>
                </div>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="addAttribute"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>{{ $t('general.add') }}</span>
                </b-button>

                <b-row
                  v-if="errors && errors.attributes"
                >
                  <b-col cols="12">
                    <b-form-invalid-feedback :state="false">
                      {{ errors.attributes[0] }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-tab>
            <b-tab :title="$t('administration.navigation.extensions')">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.top_extension_id.label')"
                    label-for="top_extension_id"
                    :state="errors && errors.top_extension_id ? false : null"
                  >
                    <v-select
                      id="top_extension_id"
                      v-model="item.top_extension_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="extensionOptions"
                      :reduce="val => val.value"
                      input-id="top_extension_id"
                      :searchable="true"
                      @search="searchExtension"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.top_extension_id">
                      {{ errors.top_extension_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.bottom_extension_id.label')"
                    label-for="bottom_extension_id"
                    :state="errors && errors.bottom_extension_id ? false : null"
                  >
                    <v-select
                      id="bottom_extension_id"
                      v-model="item.bottom_extension_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="extensionOptions"
                      :reduce="val => val.value"
                      input-id="bottom_extension_id"
                      :searchable="true"
                      @search="searchExtension"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.bottom_extension_id">
                      {{ errors.bottom_extension_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'products-index', query: $route.query }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import draggable from 'vuedraggable'
import htmlEditButton from 'quill-html-edit-button'
import EditorMixin from '@/mixins/EditorMixin'

import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormInvalidFeedback,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BLink,
  BFormFile,
  BFormTextarea,
  BFormDatepicker,
  BOverlay,
} from 'bootstrap-vue'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CategoryMixin from '@/mixins/CategoryMixin'
import StatusMixin from '@/mixins/StatusMixin'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'
import InitFileManager from '@/components/InitFileManager'

Quill.register('modules/htmlEditButton', htmlEditButton)

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BImg,
    BFormFile,
    BFormTextarea,
    quillEditor,
    Treeselect,
    BFormDatepicker,
    BOverlay,
    InitFileManager,
    draggable,
  },
  mixins: [CategoryMixin, SweetAlertMixin, StatusMixin, EditorMixin],
  data() {
    return {
      item: null,
      languages: [],
      stockStatusOptions: [
        { label: this.$t('general.stock_statuses.available'), value: 'available' },
        { label: this.$t('general.stock_statuses.not_available'), value: 'not_available' },
        { label: this.$t('general.stock_statuses.waiting'), value: 'waiting' },
        { label: this.$t('general.stock_statuses.pre_order'), value: 'pre_order' },
      ],
      loading: {
        categories: false,
        stocks: false,
        roles: false,
        attributes: false,
      },
      categoryOptions: [],
      stickersOptions: [],
      stocksOptions: [],
      rolesOptions: [],
      attributesOptions: [],
      attributeValuesOptions: [],
      extensionOptions: [],
      productOptions: [],
      apiUrl: process.env.VUE_APP_APP_URL,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/administration/products/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        const products = _.reduce(data.sets, (result, set) => {
          result.push(set.entity)
          return result
        }, [])

        this.productOptions = this.$options.filters.transformForVSelect(products, 'id', 'title')

        if (!_.isNull(data.topExtension) && !_.find(this.extensionOptions, { value: data.topExtension.id })) {
          this.extensionOptions.push({
            value: data.topExtension.id,
            label: data.topExtension.title,
          })
        }

        if (!_.isNull(data.bottomExtension) && !_.find(this.extensionOptions, { value: data.bottomExtension.id })) {
          this.extensionOptions.push({
            value: data.bottomExtension.id,
            label: data.bottomExtension.title,
          })
        }

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'products-index' })
        }
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    selectedAvatar(data) {
      this.item.avatar = data
    },
    selectedGallery(data) {
      this.item.gallery = data
    },
    async searchProduct(query) {
      await this.$http.get('/api/administration/products', {
        params: {
          query,
        },
      })
        .then(response => {
          this.productOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async searchExtension(query) {
      await this.$http.get('/api/administration/extensions', {
        params: {
          query,
        },
      })
        .then(response => {
          this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async loadDataTab() {
      if (!Object.keys(this.categoryOptions).length) {
        this.loading.categories = false

        await this.$http.get('/api/administration/categories', {
          params: {
            type: 'product',
            status: 'enabled',
          },
        })
          .then(response => {
            this.categoryOptions = response.data
            this.loading.categories = true
          })
          .catch(() => {
            this.loading.categories = true
          })
      }
      if (!Object.keys(this.stickersOptions).length) {
        this.loading.categories = false

        await this.$http.get('/api/stickers', { params: { status: 'enabled', per_page: 100 } })
          .then(response => {
            this.stickersOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
            this.loading.categories = true
          })
          .catch(() => {
            this.loading.categories = true
          })
      }
    },
    loadStocks() {
      if (Object.keys(this.stocksOptions).length) {
        return
      }

      this.loading.stocks = false

      this.$http.get('/api/stocks', { params: { status: 'enabled', per_page: 100 } })
        .then(response => {
          this.stocksOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
          this.loading.stocks = true
        })
        .catch(() => {
          this.loading.stocks = true
        })
    },
    loadRoles() {
      if (Object.keys(this.rolesOptions).length) {
        return
      }

      this.loading.roles = false

      this.$http.get('/api/roles', { params: { mode: 'client', per_page: 100 } })
        .then(response => {
          this.rolesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
          this.loading.roles = true
        })
        .catch(() => {
          this.loading.roles = true
        })
    },
    loadAttributes() {
      if (Object.keys(this.attributesOptions).length) {
        return
      }

      this.loading.attributes = false

      this.$http.get('/api/attributes', { params: { status: 'enabled', per_page: 100, relations: 'values.translations' } })
        .then(response => {
          this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')

          const vm = this

          _.each(response.data.data, item => {
            vm.attributeValuesOptions[`attribute_${item.id}`] = vm.$options.filters.transformForVSelect(item.values, 'id', 'title')
          })

          this.loading.attributes = true
        })
        .catch(() => {
          this.loading.attributes = true
        })
    },
    async loadAttributeValue(id, index) {
      this.item.attributes[index].attribute_value_id = null

      if (this.attributeValuesOptions && _.has(this.attributeValuesOptions, `attribute_${id}`)) {
        return
      }

      const response = await this.$http.get(`/api/attributes/${id}/values`)

      this.$set(this.attributeValuesOptions, `attribute_${id}`, await this.$options.filters.transformForVSelect(response.data.data, 'id', 'title'))
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        slug: this.item.slug,
        video: this.item.video,
        '1c_id': this.item['1c_id'],
        categories: this.item.categories,
        stocks: this.item.stocks,
        stickers: this.item.stickers,
        prices: this.item.prices,
        sets: this.item.sets,
        discounts: this.item.discounts,
        attributes: this.item.attributes,
        avatar: this.item.avatar,
        gallery: this.item.gallery,
        status: this.item.status,
        indexing_status: this.item.indexing_status,
        description: this.item.description,
        meta_description: this.item.meta_description,
        meta_h1: this.item.meta_h1,
        meta_keywords: this.item.meta_keywords,
        meta_title: this.item.meta_title,
        canonical: this.item.canonical,
        code: this.item.code,
        sku: this.item.sku,
        upc: this.item.upc,
        ean: this.item.ean,
        isbn: this.item.isbn,
        min_quantity: this.item.min_quantity,
        available_at: this.item.available_at,
        novelty_until: this.item.novelty_until,
        stock_status: this.item.stock_status,
        top_extension_id: this.item.top_extension_id,
        bottom_extension_id: this.item.bottom_extension_id,
      }

      this.$http.put(`/api/administration/products/${this.item.id}`, data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Updated!',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    addStock() {
      this.item.stocks.push({
        quantity: 1,
        stock_id: null,
      })
    },
    removeStock(index) {
      this.item.stocks.splice(index, 1)
    },
    addPrice() {
      this.item.prices.push({
        price: 1,
        role_id: null,
      })
    },
    removePrice(index) {
      this.item.prices.splice(index, 1)
    },
    addSet() {
      this.item.sets.push({
        price: 1,
        entity_id: null,
      })
    },
    removeSet(index) {
      this.item.sets.splice(index, 1)
    },
    addDiscount() {
      this.item.discounts.push({
        price: 1,
        started_at: new Date(),
        ended_at: new Date(),
        role_id: null,
      })
    },
    removeDiscount(index) {
      this.item.discounts.splice(index, 1)
    },
    addAttribute() {
      this.item.attributes.push({
        attribute_id: null,
        attribute_value_id: null,
        is_link: false,
      })
      this.attributeValuesOptions.push([])
    },
    removeAttribute(index) {
      this.item.attributes.splice(index, 1)
      this.attributeValuesOptions.splice(index, 1)
    },
    transformData(item) {
      const data = {
        id: item.id,
        slug: item.slug,
        video: item.video,
        '1c_id': item['1c_id'],
        avatar: this.$options.filters.singleMedia(item.avatar),
        gallery: this.$options.filters.multipleMedia(item.gallery),
        categories: item.categories,
        stocks: item.stocks,
        prices: [],
        sets: [],
        discounts: [],
        attributes: [],
        stickers: item.stickers,
        status: item.status,
        indexing_status: item.indexing_status,
        code: item.code,
        sku: item.sku,
        upc: item.upc ?? 'disabled',
        ean: item.ean,
        isbn: item.isbn,
        min_quantity: item.min_quantity,
        available_at: item.available_at ? item.available_at : new Date(),
        novelty_until: item.novelty_until ? item.novelty_until : new Date(),
        stock_status: item.stock_status,
        title: {},
        description: {},
        meta_description: {},
        meta_h1: {},
        meta_keywords: {},
        meta_title: {},
        canonical: {},
        top_extension_id: item.top_extension_id,
        bottom_extension_id: item.bottom_extension_id,
      }

      if (!_.isNull(item.topExtension)) {
        data.top_extension_id = item.topExtension.id
      }

      if (!_.isNull(item.bottomExtension)) {
        data.bottom_extension_id = item.bottomExtension.id
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
        data.meta_description[language.code] = translation ? translation.meta_description : null
        data.meta_h1[language.code] = translation ? translation.meta_h1 : null
        data.meta_keywords[language.code] = translation ? translation.meta_keywords : null
        data.meta_title[language.code] = translation ? translation.meta_title : null
        data.canonical[language.code] = translation ? translation.canonical : null
      })

      data.categories = _.map(data.categories, 'id')
      data.stickers = _.map(data.stickers, 'id')

      data.stocks = _.reduce(data.stocks, (result, stock) => {
        result.push({
          stock_id: stock.id,
          quantity: stock.quantity,
        })
        return result
      }, [])

      data.prices = _.reduce(item.prices, (result, price) => {
        result.push({
          id: price.id,
          role_id: price.role_id,
          price: price.price,
        })
        return result
      }, [])

      data.sets = _.reduce(item.sets, (result, set) => {
        result.push({
          id: set.id,
          entity_id: set.entity_id,
          price: set.price,
        })
        return result
      }, [])

      data.discounts = _.reduce(item.discounts, (result, discount) => {
        result.push({
          id: discount.id,
          role_id: discount.role_id,
          price: discount.price,
          started_at: discount.started_at,
          ended_at: discount.ended_at,
        })
        return result
      }, [])

      data.attributes = _.reduce(item.attributes, (result, attribute) => {
        result.push({
          id: attribute.id,
          attribute_id: attribute.attribute_id,
          attribute_value_id: attribute.attribute_value_id,
          is_link: attribute.is_link,
        })
        return result
      }, [])

      return data
    },
    toList() {
      router.replace({
        name: 'products-index',
        query: { ...this.$route.query, ...{ updated: Math.floor(Date.now() / 1000) } },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

.back-to-list {
  position: absolute;
  top: -75px;
  right: -20px;
}

.view-on-front {
  position: absolute;
  top: -75px;
  right: 60px;
}
</style>
